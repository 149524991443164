<template>
    <div
        class="d-flex align-items-center fake-input"
        data-bs-toggle="modal"
        data-bs-target="#search-mask"
    >
        <IconsSearch class="flex-shrink-0 fake-input__icon" />
        <span class="equal-base equal-base--tighter fake-input__term">
            {{ q }}
        </span>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    q: {
        type: String,
        default: '',
    },
    placeholder: {
        type: String,
        default: 'Suche',
    },
});

const displayInput = computed(() => {
    if (props.q) {
        return props.q;
    }

    return props.placeholder;
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.fake-input {
    height: 50px;
    padding: 0 30px;
    border: 1px solid $color-dark-disabled;
    border-radius: 25px;

    &__icon {
        fill: $color-disabled;
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }

    &__term {
        color: $color-disabled;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
</style>
